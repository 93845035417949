.form-item {
  width: 100% !important;
  height: 50px !important;
  top: 20px !important;
  color: black !important;
  font-size: 16px !important;

  input::placeholder {
    font-size: 16px !important;
  }
}
.form-item-search {
  width: 100% !important;
  height: 40px !important;
  top: 25px !important;
  color: black !important;
  font-size: 16px !important;

  background: #ffffff;
  /* Elevation / 6 */

  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  input::placeholder {
    font-size: 16px !important;
  }
}
//TODO refactor this overrriding
.ant-select-selector {
  height: 50px !important;
}
.ant-select-selection-search-input {
  height: 50px !important;
}
.ant-select-selection-placeholder {
  margin-top: 10px !important;
}
.ant-select-selection-item {
  margin-top: 10px !important;
}

.form-item-search:focus,
.form-item-search:hover,
.form-item-search:focus-within {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 1px 18px rgba(0, 0, 0, 0.03);
  border-color: transparent !important;
  // border-color: unset !important;
  // border-right-width: unset !important;
  // outline: 0;
}

.title-form-item {
  @extend .form-item;
  top: unset !important;
}

.title-textarea-form-item {
  @extend .title-form-item;
  height: auto !important;
}
.create-element {
  min-height: 50px !important;
  width: 100% !important;
  // height: 50px !important;
  // top: 20px !important;
  color: black !important;
}
.create-element-invisible {
  width: 100% !important;
  border: none !important;
  background-color: transparent !important;
  color: black !important;
  outline: none !important;
}
.create-element-invisible:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.input-layout {
  background-color: transparent !important;
}

.create-element-invisible {
  border: none !important;
}

.float-label {
  height: auto;
  background-color: transparent !important;
  position: relative;
  margin-bottom: -12px;
}

.label {
  font-size: 16px !important;
  opacity: 0;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20% !important;
  top: 27px !important;
  transition: 0.2s ease all;
}

.label-float {
  position: absolute;
  top: 12px !important;
  left: 2% !important;
  font-size: 12px !important;
  opacity: 1 !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
  background-color: white !important;
  z-index: 1000;
  color: rgba(20, 20, 20, 0.5);
}

div[role="alert"] {
  width: 300px;
  margin-top: 20px;
  z-index: 3;
  position: relative;
  text-align: start;
  margin-bottom: 7px;
}
.ant-select-selector {
  text-align: start;
  // height: 50px !important;
  color: black !important;
}
.wider-select {
  height: 50px !important;
}
.input-text {
  font-size: 14px !important;
}
.select-input {
  .float-label-select {
    height: 60px;
    // background-color: #fff !important;
    position: relative;
    margin-bottom: -12px;
  }
  .label-float-select {
    position: absolute;
    top: -10px !important;
    left: 2% !important;
    font-size: 12px !important;
    opacity: 1 !important;
    padding-right: 2px !important;
    padding-left: 2px !important;
    background-color: white !important;
    z-index: 1000;
    color: rgba(20, 20, 20, 0.5);
  }
  .select-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .select-text-date {
    padding-top: 10px;
    padding-left: 10px;
  }
  .select-text-date-2 {
    padding-top: 10px;
    padding-right: 15px;
  }
}
// .ant-select-selector input {
//   margin-top: 8px !important;
//   color: black !important;
//   z-index: -200 !important;
// }
// .ant-select-selection-placeholder {
//   margin-top: 8px !important;
// }
// .anticon .anticon-close-circle .ant-input-clear-icon {
//   background-color: red !important;
//   color: red !important;
// }
.fieldset-input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  legend {
    width: unset;
    margin-left: 6px;
    margin-bottom: unset;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: rgba(20, 20, 20, 0.54);
  }
  .ant-input {
    border: unset;
    padding: 14px 12px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #141414;
    &:focus {
      border-color: unset;
      border-right-width: unset !important;
      outline: 0;
      box-shadow: unset !important;
    }
    .ant-input-focused {
      border-color: unset;
      border-right-width: unset !important;
      outline: 0;
      box-shadow: unset !important;
    }
  }
}
.radio-input {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  .ant-radio-inner {
    background: transparent;
    border-color: rgba(20, 20, 20, 0.54);
  }
  .ant-radio-wrapper {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    text-transform: capitalize;
    color: #141414;
    &:hover {
      .ant-radio {
        border-color: #141414;
      }
    }
  }
  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: #141414;
      }
    }
  }
  .ant-radio-input {
    &:focus {
      + {
        .ant-radio-inner {
          border-color: #141414;
        }
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      &::after {
        background-color: #141414;
      }
      border-color: #141414;
    }
  }
}

.error-box {
  padding-top: 7px;
}

.error-box .error-message {
  display: inline-grid;
}

.checkbox-input {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        &::after {
          height: 11.143px;
        }
      }
    }
    .ant-checkbox-checked {
      &::after {
        border-color: #141414;
      }
      .ant-checkbox-inner {
        background-color: #141414;
        border-color: #141414;
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-bottom: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #141414;
    text-transform: capitalize;
    &:hover {
      .ant-checkbox-inner {
        border-color: #141414;
      }
    }
  }
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: #141414;
      }
    }
  }
  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: #141414;
        }
      }
    }
  }
}
