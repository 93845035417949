.irys-stat-card {
  height: 100%;
  border-radius: 4px !important;
  box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
  .value {
    font-family: IBMPlex-Bold;
    color: #141414;
    font-size: 30px;
  }

  .name {
    font-family: IBMPlex-Reg;
    color: #5c5c5c;
    font-size: 18px;
    padding: 8px 0;
  }

  .icon-container {
    mix-blend-mode: normal;
    border-radius: 10px;
    height: 50px;
    width: 54px;
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    align-self: center;
    text-align: center;
    justify-content: center !important;
    padding: 1px !important;
  }
}
