.analytics {
  .graphs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 4px;
    box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);

    .graph-container {
      height: 40vh;

      justify-content: space-around;
      .graph-content {
        padding-left: 2vh;
        padding-right: 2vh;
        padding-top: 1vh;
        width: 98%;
        height: 95%;
        background-color: white;
        .title {
          height: 20%;
          line-height: 32px;
          letter-spacing: 0.15px;
          color: #141414;
          font-size: 16px;
          font-weight: bold;
          text-align: start;
          width: 75%;
        }
        .select {
          height: 20%;
          text-align: end;
          width: 25%;
          .selectbox-title {
            font-weight: 300;
            font-size: 14px;
            letter-spacing: 0.15px;
          }
        }
      }
      .graph {
        width: 100%;
        height: 100%;
        min-height: fit-content;
      }
      .graph-with-footer {
        min-height: fit-content;
        width: 100%;
        height: 65%;
      }
      .table-header {
        width: 98%;
        height: 4vh;
        background: #fafafa;
        border-radius: 8px 8px 8px 8px;
        border-top: 1px solid rgba(185, 185, 185, 0.3);
        border-left: 1px solid rgba(185, 185, 185, 0.3);
        border-bottom: 1px solid rgba(185, 185, 185, 0.3);
        border-right: 1px solid rgba(185, 185, 185, 0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3px 10px 3px 10px;
        .table-header-title {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 31px;
          text-align: right;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #5c5c5c;
        }
      }
      .table-content {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 3px 10px 3px 5px;
        overflow-x: scroll;
        .table-content-wrapper {
          width: 100%;
          min-height: 50px;
          height: fit-content;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #e0e0e0;
          .table-record-keyname {
            width: 85%;
            height: 100%;
          }
          .table-record-value {
            width: 15%;
            height: 100%;
          }
          .table-record-content {
            padding: 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #141414;
            .social-bar-container {
              display: flex;
              flex-direction: row;
            }
          }
        }
      }
      .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2px;
        width: 100%;
        height: 15%;
        .feeling-container {
          display: flex;
          flex-direction: row;
          .feeling-title {
            margin-top: 4px;
            margin-left: 5px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.15px;
            color: #141414;
          }
        }
      }
    }
  }
}
@media (min-width: 1800px) {
  .graph-container {
    height: 55vh !important;
  }
}
@media (max-width: 400px) {
  .graph-container {
    height: 35vh !important;
    width: 100%;
  }
}
