.inbox {
  .filter {
    padding-left: 1vw;
    margin: 5px;
    height: 8vh;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
    .select {
      .ant-select-selector {
        max-width: fit-content;
        min-width: 100px;
        height: 30px !important;
        padding-left: 10px;
        margin: 0 2px 0 10px;
        border-radius: 5px;
      }
      .ant-select-selection-search-input {
        height: 30px !important;
      }
      .ant-select-selection-placeholder {
        margin-top: 4px !important;
      }
      .ant-select-selection-item {
        margin-top: 4px !important;
      }
      .selectbox-title {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .content {
    height: fit-content;
    margin: 5px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 23px rgba(233, 233, 233, 0.4);
    .social-panel {
      width: 50%;
      .alert-list {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
        padding: 10px;
        font-weight: bold;
        width: 100%;
        height: 40px;
      }
      .alert-panel {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        height: 50px;
        padding-top: 10px;
        .alert-panel-title {
          font-size: 16px;
          font-weight: bold;
        }
        .alert-panel-subtitle {
          font-size: 14px;
          color: rgba(20, 20, 20, 0.54);
        }
        .select-subtitle {
          z-index: -1;
          color: rgba(20, 20, 20, 0.54);
          padding-left: 10px;
          margin-top: -10px;
        }
      }
      .social-cards-container {
        -ms-overflow-style: none !important; /* IE and Edge */
        scrollbar-width: none !important; /* Firefox */
        max-height: 100vh;
        overflow-x: scroll;
        .social-card {
          width: 100% !important;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          height: 100px;
          border-bottom: 1px solid #e0e0e0;
          .social-card-data {
            height: 100%;
            width: 90%;
            font-size: 12px;
            text-align: left;
            padding: 12px;
            margin-top: 15px;
            letter-spacing: 0.4px;
            color: rgba(20, 20, 20, 0.54);
          }
          .social-card-title {
            letter-spacing: 0.1px;
            font-size: 14px;
            font-weight: bold;
            color: #141414;
          }
          .social-card-content {
            letter-spacing: 0.1px;
            font-size: 12px;
            font-weight: bold;
            color: #141414;
            // color: rgba(20, 20, 20, 0.54);
          }
          .social-image {
            width: 35px;
          }
        }
      }
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      /* Optional: show position indicator in red */
      ::-webkit-scrollbar-thumb {
        background: #ff0000;
      }
    }
    .individual-content {
      border-left: 1px solid #e0e0e0;
      width: 50%;
      .sentiment-header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e0e0e0;
        padding-top: 12px;
        width: 100%;
        height: 50px;
        font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        color: #5c5c5c;
        letter-spacing: 0.15px;
        .header-title {
          margin-left: 5vh;
        }
      }
      .content-card {
        overflow: hidden;
        margin-left: 5vh;
        margin-top: 30px;
        width: 80%;
        height: fit-content;
      }
    }
  }
}
